<template>
  <div class="">
    <Card class="p-m-2">
      <template #title> Mükellefler</template>
      <template #content>
        <div class="top-button-area">
          <Button icon="pi pi-plus " class="p-button-sm p-mr-2 p-mb-2 yeni" label="Yeni" @click="_MukellefModal()" />
        </div>
        <DataTable v-if="DataTable_render" :columns="columns" :data="MukellefListesi" :selectionmode="false"
          v-on:set-child-data="getDataChild" />
      </template>
    </Card>

    <Dialog v-model:visible="MukellefModal" :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
      :style="{ width: '50vw' }" :modal="true">
      <template #header>
        <h4>Mukellef</h4>
      </template>

      <div class="p-grid p-fluid p-formgrid">
        <div class="p-field p-col-6">
          <span for="spanUnvan">Ünvan</span>
          <InputText type="text" v-model="txtUnvan" aria-labelledby="spanUnvan" />
        </div>
        <div class="p-field p-col-6">
          <span for="spanAdSoyad">Ad Soyad</span>
          <InputText type="text" v-model="txtAdSoyad" aria-labelledby="spanAdSoyad" />
        </div>
        <div class="p-field p-col-6">
          <span for="spanTCKN">TCKN</span>
          <InputText type="text" v-model="txtTCKN" aria-labelledby="spanTCKN" />
        </div>
        <div class="p-field p-col-6">
          <span for="spanVKN">VKN</span>
          <InputText type="text" v-model="txtVKN" aria-labelledby="spanVKN" />
        </div>
        <div class="p-field p-col-6">
          <span for="spanTelNo1">Telefon No 1</span>
          <InputMask id="txtTelefonNo1" type="text" mask="(999) 999-9999" v-model="txtTelNo1" />
        </div>
        <div class="p-field p-col-6">
          <span for="spanTelNo2">Telefon No 2</span>
          <InputMask id="txtTelefonNo2" type="text" mask="(999) 999-9999" v-model="txtTelNo2" />

        </div>
        <div class="p-field p-col-6">
          <span for="spanEmail1">E-Mail 1</span>
          <InputText type="text" v-model="txtEmail1" aria-labelledby="spanEmail1" />
        </div>
        <div class="p-field p-col-6">
          <span for="spanEmail2">E-Mail 2</span>
          <InputText type="text" v-model="txtEmail2" aria-labelledby="spanEmail2" />
        </div>
        <div class="p-field p-col-6">
          <div class="flex align-items-center">
            <Checkbox v-model="SMSBildirim" :binary="true" />
            <label for="ingredient1" class="ml-2"> Sms Bildirim </label>
          </div>
        </div>
        <div class="p-field p-col-6">
          <div class="flex align-items-center">
            <Checkbox v-model="MailBildirim" :binary="true" />
            <label for="ingredient2" class="ml-2"> Mail Bildirim </label>
          </div>
        </div>

      </div>
      <template #footer>
        <Button label="Tamam" icon="pi pi-check" class="p-button-sm p-button-success"
          @click="this.handleClick(!$invalid)" autofocus />
        <Button label="Kapat" icon="pi pi-times" class="p-button-sm p-button-danger" @click="this._MukellefModal()" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DataTable from "../DataTable/DataTable.vue";
import GlobalServis from "../../../services/GlobalServis";

export default {
  data() {
    return {
      DataTable_render: true,
      MukellefListesi: [],
      MukellefModal: false,
      MukellefPopUpDurumu: { type: Boolean, value: null }, // True = Yeni Kullanici, False = Edit Mukellef
      columns: [
        { field: "AdSoyad", header: "Ad Soyadı" },
        { field: "Unvan", header: "Ünvan" },
      ],
      txtUnvan: null,
      txtAdSoyad: null,
      txtTCKN: null,
      txtVKN: null,
      txtTelNo1: null,
      txtTelNo2: null,
      txtEmail1: null,
      txtEmail2: null,
      Mukellef_rowid: null,
      SMSBildirim: 0,
      MailBildirim: 0
    };
  },
  methods: {
    getDataChild(getdata_child) {
      this.resetForm();
      this.MukellefPopUpDurumu = false;
      this.MukellefModal = !this.MukellefModal;
      this.Mukellef_rowid = getdata_child.data.Mukellef_rowid;
      let data = {
        Mukellef_rowid: getdata_child.data.Mukellef_rowid,
      };
      GlobalServis.GlobalServis("POST", "GetMukellefInfo", data).then(
        (res) => {
          if (res.status == 200) {
            this.txtUnvan = res.data[0].Unvan;
            this.txtAdSoyad = res.data[0].AdSoyad;
            this.txtTCKN = res.data[0].TCKN;
            this.txtVKN = res.data[0].VKN;
            this.txtTelNo1 = res.data[0].TelefonNo1;
            this.txtTelNo2 = res.data[0].TelefonNo2;
            this.txtEmail1 = res.data[0].Email1;
            this.txtEmail2 = res.data[0].Email2;
            this.SMSBildirim = res.data[0].SmsBildirim;
            this.MailBildirim = res.data[0].EmailBildirim;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      );
    },
    handleClick(isFormValid) {
      this.submitted = true;
      if (isFormValid) {
        let data = {
          Mukellef_rowid: this.Mukellef_rowid,
          AdSoyad: this.txtAdSoyad,
          Unvan: this.txtUnvan,
          TCKN: this.txtTCKN,
          VKN: this.txtVKN,
          TelefonNo1: this.txtTelNo1,
          TelefonNo2: this.txtTelNo2,
          Email1: this.txtEmail1,
          Email2: this.txtEmail2,
          SmsBildirim: this.SMSBildirim,
          EmailBildirim: this.MailBildirim
        };
        if (this.MukellefPopUpDurumu) {
          // Yeni Mukellef
          GlobalServis.GlobalServis("POST", "SetMukellef", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Mukellef oluşturulmuştur",
                  life: 3000,
                });
                this.Refresh();
                this._MukellefModal();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        } else {
          // Edit Mukellef
          GlobalServis.GlobalServis("POST", "UpdateMukellef", data).then(
            (res) => {
              if (res.status == 200 && res.data == "") {
                this.$toast.add({
                  severity: "success",
                  summary: "Başarılı",
                  detail: "Mukellef düzenlemiştir",
                  life: 3000,
                });
                this.Refresh();
                this._MukellefModal();
              } else if (res.status == 200 && res.data != "") {
                this.$toast.add({
                  severity: "warn",
                  summary: "Uyarı",
                  detail: res.data,
                  life: 3000,
                });
              } else {
                this.$toast.add({
                  severity: "error",
                  summary: "Hata",
                  detail: "Hata oldu",
                  life: 3000,
                });
              }
            }
          );
        }
      } else {
        this.$toast.add({
          severity: "warn",
          summary: "Gerekli Alan Uyarısı",
          detail: "Lütfen tüm alanları doldurunuz",
          life: 3000,
        });
      }
    },
    _MukellefModal() {
      this.MukellefPopUpDurumu = true;
      this.MukellefModal = !this.MukellefModal;
      if (this.MukellefModal) {
        this.resetForm();
      }
    },
    resetForm() {
      // this.$reset;
      this.txtUnvan = "";
      this.txtAdSoyad = "";
      this.txtTCKN = "";
      this.txtVKN = "";
      this.txtTelNo1 = "";
      this.txtTelNo2 = "";
      this.txtEmail1 = "";
      this.txtEmail2 = "";
      this.SMSBildirim = 0;
      this.MailBildirim = 0;
    },
    Refresh() {
      this.DataTable_render = false;
      this.$nextTick(() => {
        this.InitServis();
        this.DataTable_render = true;
      });
    },
    InitServis() {
      GlobalServis.GlobalServis("GET", "GetMukellefTablosu", "").then(
        (res) => {
          if (res.status == 200) {
            this.MukellefListesi = res.data;
          } else if (res.status == 400) {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Önemli bir hata meydana geldi",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Hata",
              detail: "Bilinmeyen bir hata meydana geldi",
              life: 3000,
            });
          }
        }
      )

    },
  },
  mounted() {
    this.InitServis();
  },
  components: {
    DataTable,
  },

};
</script>

<style lang="scss" scoped>
.top-button-area .p-button.yeni {
  background: linear-gradient(to left,
      var(--purple-600) 50%,
      var(--purple-700) 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: background-position 0.5s ease-out;
  color: #fff;
  border-color: var(--purple-700);
}

.top-button-area .p-button.yeni:hover {
  background-position: left bottom;
}

.top-button-area .p-button.yeni i {
  background-color: var(--purple-700);
}

.top-button-area .p-button.yeni:focus {
  box-shadow: 0 0 0 1px var(--purple-400);
}
</style>